.alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    svg {
        animation-name: animateIcon;
        animation-duration: .6s;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        animation-fill-mode: forwards;
    }
    p {
        text-align: justify;
    }
}

.success {
    svg {
        fill: rgba($color: #5a965a, $alpha: 1.0);
    }
}

@keyframes animateIcon {
    0% {
        width: 0;
        height: 0;
    }
    100% {
        width: 50px;
        height: 50px;
    }
}