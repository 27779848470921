.medaiCard {
    position: relative;
    &_mediaFlag {
    }
}
.mediaCard_mediaFlag {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
    padding: 3px;
    z-index: 10;
    background-color: #e6b060;
    border-radius: 4px;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        fill: white;
    }
}