@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* Custom scrollbar styles for WebKit browsers */
::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
  height: 5px;
  /* Height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #28241f;
  /* Background of the scrollbar track */
  /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #5e5144;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #4b4036;
  /* Color of the thumb on hover */
}

.tooltip {
  position: relative;
  width: 100%;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

em-emoji-picker {
  width: 320px;
  height: 361px;
}
