.realtedTabs {
    display: flex;
    gap: 25px;
    button {
        opacity: 0.6;
        position: relative;
        transition-property: opacity;
        transition-duration: .3s;
        transition-timing-function: ease-in-out;
        display: block;
        &::before {
            content: "";
            position: absolute;
            width: 0%;
            height: 4px;
            bottom: -12px;
            right: 0;
            background-color: #ffffff;
            transition-property: width;
            transition-duration: .3s;
            transition-timing-function: ease-in-out;
            display: block;
        }
        &.light_mode::before {
            background-color: #000000;
        }
        &.selected {
            opacity: 1;
            &::before {
                width: 100%;
            }
        }
        &:last-child::before {
            right: unset;
            left: 0;
        }
    }
}