.popup {
    &_bg {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10000;
        background-color: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease-in-out;
        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }
    &_wrapper {
        //#5e5144
        background-color: rgba($color: #5e5144, $alpha: 1.0);
        max-width: 510px;
        width: 100%;
        padding: 40px;
        border-radius: 16px;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }
    &_closeBtn {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 7px;
        right: 12px;
        cursor: pointer;
        fill: #FFFFFF;
    }
    &_text {
        text-align: center;
        font-size: 18px;
    }
    &_action_call {
        text-align: center;
        font-size: 24px;
    }
    &_form {
        max-width: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .form_group {
            .form_row {
                position: relative;
                input[type=text],
                input[type=email] {
                    width: 100%;
                    background-color: transparent;
                    border: none;
                    outline: none !important;
                    --tw-ring-color: transparent;
                    border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: 0.3);
                    transition: border-bottom .3s ease-in-out;
                }
                input[type=text] + label,
                input[type=email] + label {
                    position: absolute;
                    left: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                    opacity: 1;
                    transition-property: top transform opacity;
                    transition-duration: .3s;
                    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }
                input[type=text]:focus,
                input[type=email]:focus {
                    border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: 1.0);
                }
                input[type=text]:focus + label,
                input[type=email]:focus + label,
                input[type=text]:not(:placeholder-shown) + label,
                input[type=email]:not(:placeholder-shown) + label {
                    top: -5px;
                    opacity: 0.6;
                }
                .form_submit {
                    background-color: #375A7F;
                    color: #fff;
                    padding: 12px 24px;
                    border: none;
                    border-radius: 8px;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                    transition: transform 0.2s ease-in-out;
                    position: relative;
                    overflow: hidden;
                
                    &:hover {
                        transform: scale(1.05);
                    }
                
                    &:focus {
                        outline: none;
                    }
                
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        height: 100%;
                        background: rgba(255, 255, 255, 0.3);
                        border-radius: 50%;
                        transform: translate(-50%, -50%) scale(0);
                        animation: pulse 1.5s infinite ease-in-out;
                    }
                }
            }
        }
        .form_input_description {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0;
        }
    }
}

.label_warning {
    color: rgb(238, 144, 144);
    animation-name: shake;
    animation-duration: .4s;
    animation-fill-mode: forwards;
}

.login_string {
    text-align: center;
    span {
        cursor: pointer;
    }
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0.8;
    }
    50% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
}
@keyframes shake {
    0%, 100% { transform: translateX(0) translateY(-50%); }
    25% { transform: translateX(-5px) translateY(-50%); }
    50% { transform: translateX(5px) translateY(-50%); }
    75% { transform: translateX(-3px) translateY(-50%); }
  }