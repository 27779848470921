.feedbackWindow {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba($color: #000000, $alpha: 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    padding: 15px;
    &.show {
        opacity: 1;
        pointer-events: all;
    }
}

.feedbackForm {
    padding: 24px;
    background-color: rgba($color: #5e5144, $alpha: 1);
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 8px;
    position: relative;
    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
        background: transparent;
        border: none;
        outline: none;
        svg {
            width: 20px;
            height: 20px;
            fill: #ffffff;
        }
    }
    &__filesList {
        display: flex;
        flex-direction: column;
        gap: 7px;
        padding: 20px;
        max-width: 400px;
        width: 100%;
        &__file {
            display: flex;
            justify-content: flex-start;
            gap: 6px;
            span {
                font-size: 12px;
            }
            button {
                background: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                svg {
                    width: 12px;
                    height: 12px;
                    fill: #FFFFFF;
                }
            }
        }
        &__fileName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__fileSize {
            margin-left: auto;
        }
    }
    &__uploadLabel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        div {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
        &__mimes {
            font-size: 10px;
        }
        svg {
            width: 25px;
            height: 25px;
            fill: #FFFFFF;
        }
    }
    &__uploadInput {
        display: none;
    }
    &__submit {
        background-color: #375a7f;
        color: #fff;
        padding: 12px 24px;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
        position: relative;
        overflow: hidden;

        &:hover {
            transform: scale(1.05);
        }

        &:focus {
            outline: none;
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            transform: translate(-50%, -50%) scale(0);
            animation: pulse 1.5s infinite ease-in-out;
        }
    }
}
