.selected_genre {
    width: 234px;
    font-weight: 700;
    background-color: #5b5147;
    padding: 8px 20px;
    border-bottom: 1px solid #666666;
    position: relative;
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
}
.genres {
    position: absolute;
    top: 125%;
    width: 100%;
    left: 0;
    z-index: 50;
    display: flex;
    flex-direction: column;
    gap: 3px;
    background-color: #2e2e2e;
    border-radius: 6px;
    li {
        background-color: #5b5147;
        padding: 8px 20px;
        border-bottom: 1px solid #666666;
        position: relative;
        cursor: pointer;
        span.selected_li_flag {
            background-color: #ffffff;
            position: absolute;
            width: 4px;
            height: 25px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            border-radius: 2px;
        }
    }
    li:first-child {
        border-radius: 6px 6px 0 0;
    }
    li:last-child {
        border-radius: 0 0 6px 6px;
    }
}
